'use client'

import type React from 'react'
import { useFormState, useFormStatus } from 'react-dom'
import { Button } from '~/components/ui/button'
import { ErrorMessage, Field, FieldGroup, Fieldset, Label, Legend } from '~/components/ui/fieldset'
import { Input } from '~/components/ui/input'
import { Text } from '~/components/ui/text'
import { signInWithCredentials } from '~/server/auth'

function Submit() {
  const { pending } = useFormStatus()
  return (
    <Button type='submit' color='indigo' className='mt-8 w-full' disabled={pending} aria-disabled={pending}>
      {pending ? '로그인 중...' : '로그인'}
    </Button>
  )
}

export function SignInForm() {
  const [{ error }, formAction] = useFormState(signInWithCredentials, { error: '' })

  const maxLength = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length > e.currentTarget.maxLength) {
      e.currentTarget.value = e.currentTarget.value.slice(0, e.currentTarget.maxLength)
    }
  }

  return (
    <form action={formAction}>
      <Fieldset>
        <Legend className='sr-only'>로그인</Legend>
        <Text>LX Z:IN 인테리어 대리점만을 위한 AI 블로그 포스팅 시스템</Text>
        <FieldGroup>
          <Field>
            <Label>대리점 코드 (6자리)</Label>
            <Input id='id' name='id' invalid={error !== ''} maxLength={6} onInput={maxLength} />
            {error !== '' && <ErrorMessage>{error}</ErrorMessage>}
          </Field>
          <Field>
            <Label>간편비밀번호 (6자리)</Label>
            <Input type='password' name='password' invalid={error !== ''} />
            {error !== '' && <ErrorMessage>{error}</ErrorMessage>}
          </Field>
        </FieldGroup>
      </Fieldset>
      <Submit />
    </form>
  )
}
